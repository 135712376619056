export const otherAccessesOptions = [
  {
    label: 'Cientista do marketing',
    value: 'CIENTISTA_DO_MARKETING',
  },
  {
    label: 'Zapier',
    value: 'ZAPIER',
  },
  {
    label: 'Unbounce',
    value: 'UNBOUNCE',
  },
  {
    label: 'Power BI',
    value: 'POWER_BI',
  },
  {
    label: 'Laboratório financeiro',
    value: 'LAB_FINANCEIRO',
  },
];
