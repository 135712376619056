export const RoleValues = {
  READ_ONLY: {
    roleName: 'READ_ONLY',
    view: true,
    edit: false,
    delete: false,
    invite: false,
    admin: false,
    transition: false,
    interest: false,
  },
  WRITE_AND_READ: {
    roleName: 'WRITE_AND_READ',
    view: true,
    edit: true,
    invite: true,
    delete: false,
    admin: false,
  },
  CAN_NOT_SEE: {
    roleName: 'CAN_NOT_SEE',
    view: false,
    edit: false,
    invite: false,
    delete: false,
    admin: false,
    transition: false,
    interest: false,
  },
  ADMIN: {
    roleName: 'ADMIN',
    view: true,
    edit: true,
    invite: true,
    delete: true,
    admin: true,
    addFunds: true,
    bid: true,
    withdraw: true,
    transition: true,
    interest: true,
  },
  CAN_BID: {
    roleName: 'CAN_BID',
    view: true,
    bid: true,
    admin: false,
    withdraw: false,
    addFunds: false,
  },
  CAN_BID_BLACK_BOX: {
    roleName: 'CAN_BID_BLACK_BOX',
    view: true,
    bid: true,
    admin: false,
    addFunds: false,
  },
  CAN_WITHDRAW: {
    roleName: 'CAN_WITHDRAW',
    view: true,
    bid: true,
    admin: false,
    withdraw: true,
    addFunds: false,
  },
  TRANSITION_ONLY: {
    roleName: 'TRANSITION_ONLY',
    view: true,
    transition: true,
    interest: false,
    admin: false,
  },
  INTEREST_ONLY: {
    roleName: 'INTEREST_ONLY',
    view: true,
    transition: false,
    interest: true,
    admin: false,
  },
  INTEREST_AND_TRANSITION: {
    roleName: 'INTEREST_AND_TRANSITION',
    view: true,
    transition: true,
    interest: true,
    admin: false,
  },
};
