import { RoleValues } from '../../../utils/roleValuesByName';
import { CreateUserRoles } from '../../../utils/types/manageUser';
import { usersApi as api } from '../../api';

interface BodyInvite extends CreateUserRoles {
  unitId: string;
  email: string;
  seniority: string;
  mandate: string;
  familyName: string;
  givenName: string;
  personalEmail: string;
  otherAccesses: any[];
  cpf: string;
  cep: string;
  gender: string;
  phone: string;
  startedAt: Date;
  contract: File;
  investorSource: string;
  previousUnit: string | null | boolean;
  wage: number;
}

export async function createGoogleEmail({
  unitId,
  email,
  seniority,
  mandate,
  familyName,
  givenName,
  personalEmail,
  otherAccesses,
  cpf,
  cep,
  gender,
  phone,
  startedAt,
  contract,
  investorSource,
  previousUnit,
  dashboardRole,
  unitRole,
  leadbrokerRole,
  blackBoxRole,
  paymentGatewayRole,
  customerRole,
  userRole,
  headquarterRole,
  wage,
}: BodyInvite) {
  try {
    const otherAccessesInput: any = [];
    otherAccesses.forEach((item) => {
      otherAccessesInput.push(item.value);
    });

    const roles = {
      dashboard: RoleValues[dashboardRole],
      unit: RoleValues[unitRole],
      leadbroker: RoleValues[leadbrokerRole],
      blackBox: RoleValues[blackBoxRole],
      paymentGateway: RoleValues[paymentGatewayRole],
      customer: RoleValues[customerRole],
      users: RoleValues[userRole],
      headquarter: RoleValues[headquarterRole],
    };

    const formData = new FormData();
    formData.append('unitId', unitId);
    formData.append('email', email);
    formData.append('seniorityId', seniority);
    formData.append('name', `${givenName} ${familyName}`);
    formData.append('mandateId', mandate);
    formData.append('familyName', familyName);
    formData.append('givenName', givenName);
    formData.append('personalEmail', personalEmail);
    formData.append(
      'otherAccesses',
      JSON.stringify(otherAccessesInput) as unknown as string,
    );
    formData.append('cpf', cpf);
    formData.append('cep', cep);
    formData.append('gender', gender);
    formData.append('phone', phone);
    formData.append('startedAt', startedAt as unknown as string);
    formData.append('contract', contract);
    formData.append('investorSource', investorSource);
    formData.append('previousUnit', JSON.stringify(previousUnit));
    formData.append('wage', wage as unknown as string);
    formData.append('roles', JSON.stringify(roles));
    const response = await api.post(`/user/invite/create/workspace`, formData);

    const user = response.data;
    return user;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao convidar este usuário 😕`,
      err.response?.data,
    );
    return err.response?.data;
  }
}
